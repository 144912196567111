class FigureD{
	constructor(){
		let win=$(window);
		let mainContent=$(".figure-d");
		let pixiBase=new PixiBase(mainContent);
		let scene=pixiBase.getScene();

		pixiBase.addUpdate(update);

     	pixiBase.startRender();

     	let containerObj=new PIXI.Container();
	    let containerObjInner=new PIXI.Container();
	    let initCount=0;
	    let initCountMax=6;

	    let svgContainers=[];
	    let charParts=[];

	    let initEnd;

	    let boundingBox;
	    let psrtsQts=[];

	    for(var n=0;n<initCountMax;n++){
	       var svgContainer=new AnalizeSVG("img/d"+(n+1)+".svg",initFunc);
	       svgContainers.push(svgContainer);

	       var getedContainer=svgContainer.getContainer();
	       containerObjInner.addChild(getedContainer);

	    }

	    function initFunc(){
	    	initCount+=1;
	    	boundingBox=new PIXI.Rectangle();

	    	//console.log("initCount="+initCount)
	    	let addedChildLength=0;

	    	if(initCount>=initCountMax){
	    		scene.addChild(containerObj);
	    		containerObj.addChild(containerObjInner);
	    		$.each(containerObjInner.children,function(id,el){
	                 var childLength=el.children.length;
	                 //console.log("id="+id)
	                 if(id==0){
		                 psrtsQts.push(childLength);
		             }else if(id<5){
		             	addedChildLength+=childLength;
		             }else{
		             	addedChildLength+=childLength;
		             	psrtsQts.push(addedChildLength);
		             }
	                  $.each(el.children,function(id0,el0){
	                      el0.visible=false;
	                      if(id==0){
	                          charParts.push(el0);
	                      }else{
	                          charParts.push(el.children[childLength-1-id0]);
	                      }
	                 })

	            })

	            $.each( svgContainers,function(id,el){
	                 $.each(el.getBounds(),function(id0,el0){
	                     boundingBox.enlarge(el0);
	                 })
	            })

	        let scaleW=mainContent.width()/boundingBox.width;
			let scaleH=mainContent.outerHeight()/boundingBox.height;

			containerObj.scale.set(scaleW,scaleH);
			pixiBase.resizeHl(mainContent.width(),mainContent.outerHeight());

	            //console.log("w,h="+boundingBox.width+","+boundingBox.height)

	        //console.log(psrtsQts)

	            setTimeout(function(){
	            	limitNum=psrtsQts[sceneNum]-1;
	            	limitMax=limitNum+1;
		             initEnd=true;
		         },1000)

	            //containerObjInner.position.x=(boundingBox.x-boundingBox.width)/2;
            	//containerObjInner.position.y=(boundingBox.y-boundingBox.height)/2;

	    	}

	    }

	    let updateCount=0;
	    let sceneNum=0;
	    let lastSceneNum=sceneNum;
	    let limitNum;
	    let lastLimitNum=0;
	    let showEnd;
	    let updateUnit=2.5;
	    let limitMax;
	    let partUpdateCount=0;
	    let nextCharNum=0,lastCharNum=0;
	    

	    function update(){
	    	if(initEnd && !showEnd){
	    		//console.log("limitNum="+limitNum)
	    		if(sceneNum<psrtsQts.length){
	    			//console.log("sceneNum="+sceneNum)
	    			let moveRatio=partUpdateCount/limitMax;

	    			//console.log(sceneNum+":moveRatio="+moveRatio)
	    			//moveRatio=Math.pow(moveRatio,3);
	    			moveRatio=utils.getYoyoRatio(moveRatio);
	    			moveRatio=Math.sin(moveRatio*0.5*Math.PI);
	    			if(moveRatio>1){
	    				moveRatio=1;
	    			}

	    			nextCharNum=lastLimitNum+Math.floor(moveRatio*limitMax);
	    			//console.log("nextCharNum="+nextCharNum)


	    			//console.log("moveRatio="+moveRatio)
	    			
		    		//partUpdateCount+=1;
		    			
		    		if(sceneNum==lastSceneNum && updateCount>limitNum){
		    			
		    			
		    			sceneNum+=1;
		    			limitMax=psrtsQts[sceneNum]+0.5;
		    			lastLimitNum=limitNum;
		    			limitNum+=psrtsQts[sceneNum];
		    			//console.log("PLUS:"+psrtsQts[sceneNum])
		    			lastSceneNum=sceneNum;
		    			partUpdateCount=0;
		    		}else{
		    			partUpdateCount+=updateUnit;
		    		}



		    		//console.log("updateCount="+updateCount+",sceneNum="+sceneNum+",limitNum="+limitNum)
		    		
		    		
		    		if(sceneNum<psrtsQts.length-1 || updateCount<=limitNum){
		    			let difNum=nextCharNum-lastCharNum;
		    			if(nextCharNum==0){
		    				charParts[nextCharNum].visible=true;
		    			}else{
			    			for(var n=0;n<difNum;n++){
			    				charParts[lastCharNum+n+1].visible=true;
			    			}
			    		}
		    			//charParts[Math.floor(nextCharNum)].visible=true;
			    		updateCount+=updateUnit;

			    		lastCharNum=nextCharNum;
		    		
			    	}else{
			    		
			    		let difNum=charParts.length-1-lastCharNum;
			    		//console.log("showEnd,difNum="+difNum);
			    		//console.log("lastCharNum="+lastCharNum)
			    		//console.log("charParts.length="+charParts.length)
			    		for(var n=0;n<difNum;n++){
			    			//console.log("num="+(lastCharNum+n+1))
			    				charParts[lastCharNum+n+1].visible=true;
			    		}
			    		//charParts[charParts.length-1].visible=true;
			    		showEnd=true;
			    	}
	    		}
	    		
			}
	    }

	}

	

	resizeHl=(winW,winH)=>{
		if(boundingBox){

			//console.log(boundingBox)
			let scaleW=mainContent.width()/boundingBox.width;
			let scaleH=mainContent.outerHeight()/boundingBox.height;

			containerObj.scale.set(scaleW/2,scaleH/2);
			//console.log("scale="+[scaleW,scaleH])
         pixiBase.resizeHl(mainContent.width()/2,mainContent.outerHeight()/2);
        }
	}

	scrollHl=(scrollTop)=>{
		//console.log("scrollTop="+scrollTop);
	}
	
}

